import { render, staticRenderFns } from "./ChangePhone.vue?vue&type=template&id=999d3e98&scoped=true"
import script from "./ChangePhone.vue?vue&type=script&lang=js"
export * from "./ChangePhone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "999d3e98",
  null
  
)

export default component.exports