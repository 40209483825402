import Photografer from './Photografer'

export default [
    {
        path: '/photographer/:id',
        name: 'photographer_profile',
        component: Photografer,
        meta: {
            layout: 'short-layout',
            withLoader: true,
            hidePlace: true
        }
    }
];