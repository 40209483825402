import { render, staticRenderFns } from "./UserArea.vue?vue&type=template&id=19a3756b&scoped=true"
import script from "./UserArea.vue?vue&type=script&lang=js"
export * from "./UserArea.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a3756b",
  null
  
)

export default component.exports