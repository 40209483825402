<template>
  <div id="app" class="d-flex flex-column h-100">
    <mobile-menu ref="menu" v-if="$route.name !== '404'"></mobile-menu>
    <nav id="navbar" class="navbar navbar-expand-xl"
         :class="{'photographer-page':$route.name === 'photographer_profile'}" v-if="$route.name !== '404'">
      <router-link class="navbar-brand" :to="{name:'search'}">Snappy</router-link>
      <div class="navbar-address">
        <!--                <dadata-suggestions-->
        <!--                        ref="dadata"-->
        <!--                        css_class="form-control form-icon icon-search"-->
        <!--                        :placeholder="$t('base.placeholder_1')"-->
        <!--                        field-value="value"-->
        <!--                />-->
        <!--                <Autocomplete ref="place"></Autocomplete>-->
        <city-dropdown v-if="!$route.meta.hidePlace" :css-class="'form-dropdown-sm'"></city-dropdown>
      </div>
      <div class="collapse navbar-collapse flex-shrink-0" id="navbarSupportedContent">
        <div class="ml-auto"></div>
        <!--                <select-language></select-language>-->
        <select-currency></select-currency>
        <ul class="navbar-nav">
          <li>
            <router-link :to="{name:'main_page'}">{{ $t('menu.home_page') }}</router-link>
          </li>
          <li>
            <router-link :to="{name:'about'}">{{ $t('menu.about_us') }}</router-link>
          </li>
          <!--li>
              <a href="#">{{$t('menu.price')}}</a>
          </li-->
          <li>
            <router-link :to="{name:'for_photographers'}">{{ $t('menu.photographers') }}</router-link>
          </li>
          <li>
            <a href="https://t.me/snappy_me" target="_blank">{{ $t('menu.support') }}</a>
          </li>
          <!--li>
              <a href="#">{{$t('menu.blog')}}</a>
          </li-->
        </ul>
        <login v-if="$auth.check() === false"></login>
      </div>

      <auth-user v-if="$auth.check() === true"></auth-user>
      <button class="navbar-toggler" type="button" v-on:click="$refs.menu.toggle()">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
    <loader></loader>
    <slot></slot>
    <div class="footer mt-auto" v-if="$route.meta.hide_footer !== true">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-3 order-md-1 mr-md-auto">
            <router-link class="navbar-brand" :to="{name:'main_page'}">Snappy</router-link>
          </div>
          <div class="line order-md-6"></div>
          <div class="col-12 col-md-7 order-md-2">
            <ul class="navigation">
              <li>
                <router-link :to="{name:'main_page'}">{{ $t('menu.about_us') }}</router-link>
              </li>
              <!--li>
                  <a href="#">{{$t('menu.price')}}</a>
              </li-->
              <li>
                <router-link :to="{name:'for_photographers'}">{{ $t('menu.photographers') }}</router-link>
              </li>
              <li>
                <a href="https://t.me/snappy_me" target="_blank">{{ $t('menu.support') }}</a>
              </li>
              <!--li>
                  <a href="#">{{$t('menu.blog')}}</a>
              </li-->
            </ul>
          </div>
          <div class="line d-md-none"></div>
          <div class="col-12 col-md-6 order-md-4">
            <div class="contacts">
              <a href="mailto:hello@snappy.me">hello@snappy.me</a> | <a href="tel:+79252934468">+7 926 462 71 24</a>
            </div>
          </div>
          <div class="line d-md-none"></div>
          <div class="col-12 order-md-last">
            <div class="copyright d-md-flex justify-content-md-between">
              <div>© {{ cc }} Snappy. {{ $t('base.rights') }}.</div>
              <div>
                <!--	                            <img src="/assets/images/master_visa_fondy.svg" width="137" height="22" alt="Fondy" />-->
              </div>
              <div>
                <router-link :to="{name:'rules'}">{{ $t('base.conditions') }}</router-link>
                <a href="#">{{ $t('base.partners') }}</a>
              </div>
            </div>
          </div>
          <div class="line d-md-none"></div>
          <!--div class="col-6 col-md-auto order-md-3">
              <select-language></select-language>
          </div-->
          <div class="col-6 col-md-6 order-md-5">
            <ul class="nav-social d-flex align-items-center justify-content-end">
              <li><a href="https://t.me/snappy_me" target="_blank" rel="nofollow">telegram</a></li>
              <li><a href="https://wa.me/" target="_blank" rel="nofollow">whatsapp</a></li>
              <li><a href="https://www.youtube.com/channel/UCX-SfQrRNA-GShfR99t63Kw/" target="_blank" rel="nofollow">youtube</a>
              </li>
              <!--li><a href="https://www.facebook.com/Snappy-100457625023854" target="_blank" rel="nofollow">facebook</a></li-->
              <!--li><a href="https://www.instagram.com/snappy_photoshoot/" target="_blank" rel="nofollow">instagram</a></li-->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <cookie></cookie>
    <go-top></go-top>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment'

import Login from '../components/widgets/Login';
import GoTop from "../components/widgets/GoTop";
import AuthUser from "../components/widgets/AuthUser";
// import SelectLanguage from "../components/widgets/SelectLanguage";
import MobileMenu from "../components/widgets/MobileMenu";
import Loader from "./Loader";
import Autocomplete from "../components/widgets/Autocomplete";
import SelectCurrency from "../components/widgets/SelectCurrency";
import CityDropdown from "@/components/widgets/CityDropdown.vue";
import Cookie from "@/components/widgets/Cookie.vue";

export default {
  name: "ShortLayout",
  components: {
    Cookie,
    CityDropdown,
    SelectCurrency,
    // Autocomplete,
    Loader,
    MobileMenu,
    // SelectLanguage,
    AuthUser,
    GoTop,
    Login,
  },
  data() {
    return {
      cc: moment().format('YYYY')
    }
  }
}
</script>

<style scoped>

</style>