// import axios from 'axios';
import Api from '../services';

export default class ProfileApi extends Api{

    profile(id){
        return this.web.get('/user/'+id+'/profile');
    }
    getOrderData(id){
        return this.web.get('/photographer/'+id+'/order/data');
    }
    createOrder(order){
        return this.web.post('/personal/order', order);
    }
    getCalendar(photographer_id, city_id){
        return this.web.get('/photographer/calendar/'+photographer_id+'/'+city_id);
    }
    getOrders(photographer_id, date, city_id){
        return this.web.get('/photographer/order/history/'+photographer_id+'/'+date.format('YYYY')+'/' + date.format('MM')+'/'+city_id);
    }
    getPrice(user_id, type, duration, promo_code, is_full_day) {
        return this.web.post('/personal/price/' + user_id + '/'+type, {duration: duration, promo_code:promo_code, is_full_day: is_full_day});
    }
    reviews(user, limit, offset){

        const params = new URLSearchParams();
        params.append('limit', limit);
        params.append('offset', offset);

        return this.web.get('/photographer/'+user+'/reviews', {
            params: params
        });
    }
}