<template>
  <div class="order-body">
    <PaymentForm :config="paymentDetails" v-if="paymentDetails.type === 'form'"></PaymentForm>
    <b-modal :id="'modal-cancel-order'+order.uuid" centered hide-header hide-footer>
      <div class="modal-header border-bottom">
        <p class="modal-title modal-brand modal-title-sm">Отмена заказа</p>
        <div class="close" @click="$bvModal.hide('modal-cancel-order'+order.uuid)"></div>
      </div>
      <p>Вы уверены что хотите отменить <br>Заказ № {{ order.number }}?</p>
      <div class="form-row">
        <div class="col">
          <button type="submit" class="btn btn-secondary btn-block"
                  @click="$bvModal.hide('modal-cancel-order'+order.uuid)">Нет
          </button>
        </div>
        <div class="col">
          <button type="submit" class="btn btn-primary btn-block" v-on:click="cancel()">Да</button>
        </div>
      </div>
    </b-modal>

    <div class="row">
      <div class="col-9 col-md-6">
        <div class="d-flex user">
          <div>
            <div class="avatar " :style="{
                            backgroundImage: `url(${order.user.avatars['thumb-166']})`,
                            }"></div>
          </div>
          <div>
            <div class="order-number">№{{ order.number }}</div>
            <div class="order-name">{{ getName(order.user) }}</div>
            <router-link v-if="$route.name !== 'chat.area' && !isCanceled(order)"
                         :to="{name:'chat.area',params:{id:order.number}}" href="#"
                         class="order-write">{{ isPhotographer() ? 'Написать клиенту' : 'Написать фотографу' }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-3 col-md-1 order-md-last">
        <b-button v-b-toggle="'orders-more-collapse-'+order.uuid" class="order-more"></b-button>
      </div>
      <div class="col-12 d-md-none">
        <hr class="hr">
      </div>
      <div class="col-12 col-md-3 order-line">
        <p class="order-label">{{ isBeforeAction(order) ? 'Дата съемки' : 'Дата сдача фотографий' }}</p>
        <div class="order-value" :class="{hot:isHot(getShowDate(order))}">
          {{ getShowDate(order) | moment("DD.MM.YYYY") }}
        </div>
      </div>
      <div class="col-12 col-md-2 order-line">
        <p class="order-label">Стоимость</p>
        <div class="order-value">{{ order.price | number('0,0', {thousandsSeparator: ' '}) }} <i class="rub">Р</i></div>
      </div>
    </div>
    <b-collapse :id="'orders-more-collapse-'+order.uuid">
      <!--            <div class="order-step">-->
      <!--                <p><strong>Этап 1 : Подтверждение заказа</strong></p>-->
      <!--                <p>Для продолжения назначения съемки вам необзодимо подтвердить ваш заказ нажав на кнопку подтвержить за каз и все тут что сказать на кнопку подтвержить за каз и все тут что с просто подтвердить нажми на кнопку подтвержить за каз и все тут что с</p>-->
      <!--            </div>-->
      <order-map :marker="getMarker()"></order-map>
      <div class="order-address">
        <p class="order-params-label">Адрес съемки</p>
        <p class="order-params-value">{{ order.address }}</p>
      </div>
      <hr>
      <div class="order-params">
        <div class="row">
          <div class="col-12 col-md-6">
            <p class="order-params-label">Время съемки</p>
            <p class="order-params-value">{{ order.duration }} {{ $t('profile.minutes') }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p class="order-params-label">Оборудование</p>
            <p class="order-params-value">
              <span v-for="equip in order.equipment">  {{ $t('lists.equipment.' + equip) }}</span>

            </p>
          </div>
          <div class="col-12 col-md-6">
            <p class="order-params-label">Класс съемки</p>
            <p class="order-params-value"> {{ $t('lists.photosession_types.' + order.photosession_type) }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p class="order-params-label">Человек на съемке</p>
            <p class="order-params-value"> {{ $t('lists.people_count.' + order.count_people) }}</p>
          </div>
          <div class="col-12" v-if="order.services.length > 0 ">
            <p class="order-params-label">Услуги</p>
            <ul class="order-params-value no-list">
              <li v-for="service in order.services">{{ $t('lists.additional_services.' + service) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </b-collapse>
    <div v-if="!isCanceled(order)">

      <div class="row" v-if="isPhotographer()">
        <div class="col-12 col-md-6 order-md-last">
          <button class="btn btn-primary btn-block btn-text-medium" v-if="order.status.code === 'payment'"
                  v-on:click="sendAction('confirmation')">Подтвердить
          </button>
          <!--                <button class="btn btn-primary btn-block btn-text-medium" v-if="order.status.code === 'payment'" v-on:click="sendAction('confirmation')">Подтвердить</button>-->
          <button class="btn btn-primary btn-block btn-text-medium"
                  v-if="order.status.code === 'processing' && isShowDateButton(order)" v-on:click="showDateButton()">
            Указать дату сдачи фотографий
          </button>
          <button class="btn btn-primary btn-block btn-text-medium" v-if="order.status.code === 'treatment'"
                  v-on:click="sendAction('issuing')">Завершить съемку
          </button>

        </div>
        <div class="col-12 col-md-6 order-md-first">
          <button class="btn btn-secondary btn-block btn-text-medium"
                  v-on:click="$bvModal.show('modal-cancel-order'+order.uuid)">Отменить заказ
          </button>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 col-md-6 order-md-last">
          <!--                <button class="btn btn-primary btn-block btn-text-medium" v-if="order.status.code === 'start'" v-on:click="sendAction('payment')">Оплатить</button>-->
          <button class="btn btn-primary btn-block btn-text-medium" v-if="order.status.code === 'start'"
                  v-on:click="sendAction('payment')">Оплатить
          </button>
        </div>
        <div class="col-12 col-md-6 order-md-first">
          <button class="btn btn-secondary btn-block btn-text-medium"
                  v-on:click="$bvModal.show('modal-cancel-order'+order.uuid)">Отменить заказ
          </button>
        </div>
        <div class="col-12 col-md-6 order-md-first" v-if="order.status.code === 'finish'">
          <router-link :target="openInNewPage ? '_blank' : '_self'" :to="{name:'photographer_profile',params:{id:order.user.id}, query:{...this.getRepeatOrderData(order)}}" class="btn btn-secondary btn-block btn-text-medium">
            Повторить заказ
          </router-link>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-md-6 order-md-first">
          <router-link :target="openInNewPage ? '_blank' : '_self'" :to="{name:'photographer_profile',params:{id:order.user.id}, query:{...this.getRepeatOrderData(order)}}" class="btn btn-secondary btn-block btn-text-medium">
            Повторить заказ
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import OrderMap from "./OrderMap";
import PersonalAreaApi from "../../../service";
import PaymentForm from "@/components/payment/PaymentForm.vue";

let api = new PersonalAreaApi();
export default {
  name: "OrderDetail",
  components: {PaymentForm, OrderMap},
  props: {
    order: {
      type: Object
    }
  },
  data(){
    return{
      openInNewPage: true,
      paymentDetails: {}
    }
  },
  methods: {
    isHot(date) {
      let today = moment();
      let dateDiffInDays = moment(date).diff(today, 'days');
      if (dateDiffInDays <= 3 && dateDiffInDays > 0) {
        return true;
      }
      return false;
    },
    isCanceled(order) {
      return order.is_canceled === true;
    },
    isBeforeAction(order) {
      if (['start', 'confirmation', 'processing', 'payment'].indexOf(order.status.code) > -1) {
        return true;
      }
      return false;
    },
    getShowDate(order) {
      if (this.isBeforeAction(order)) {
        return order.start_date;
      }
      return order.photo_finished;
    },
    sendAction(action) {
      api.changeStatusOrder(this.order.uuid, action).then((res) => {
        if (action === 'payment') {
          this.paymentDetails = res.data.data.payment_info;
          if(this.paymentDetails.type === 'redirect') {
            this.$bvModal.show('modal-before-order-redirect');
            setTimeout(() => {
              if (res.data.data.payment_info.payment_id) {
                window.location.replace(res.data.data.payment_info.checkout_url);
              } else {
                this.$router.push({name: 'order.area'});
              }
            }, 3000);
          }
          // window.location.replace(res.data.data.payment_info.checkout_url);
        } else {
          this.order = res.data.data;
        }
      })
    },
    isShowDateButton(order) {
      let today = moment();
      if (moment(order.end_date).diff(today, 'days') <= 0) {
        return true;
      }
      return false;
    },
    showDateButton() {
      this.sendAction('treatment')
    },
    cancel() {

      api.cancel(this.order.uuid).then(() => {
        let order = JSON.parse(JSON.stringify(this.order));
        order.is_canceled = true;
        this.$root.$emit('cancel_order', order);
        this.$bvModal.hide('modal-cancel-order' + this.order.uuid);
      })
    },

    getMarker() {
      return this.order.address_coordinates !== '' && this.order.address_coordinates !== null ? JSON.parse(decodeURIComponent(this.order.address_coordinates)) : {
        lat: 55.75,
        lng: 37.62
      };
    },
    getRepeatOrderData(order) {
      let order_data = {};
      order_data.city_id = order.city.id;
      order_data.duration = order.duration;
      order_data.equpment = order.equpment;
      order_data.service = order.service;
      order_data.start_date = moment().toDate();
      order_data.photosession_type = order.photosession_type;
      order_data.locations = order.locations;
      order_data.count_people = order.count_people;
      return {city: order.address ?? order.city[this.$store.state.user.options.locale], filters: JSON.stringify(order_data)};
    }
  }
}
</script>

<style scoped>

</style>