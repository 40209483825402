// import axios from 'axios';
import Api from '../services';

export default class MainApi extends Api {
    photographer(city) {
        const params = new URLSearchParams();
        if(city){
            params.append('city_id', city);
        }
        return this.web.get('/photographer', {params: params});
    }

    categories() {
        return this.web.get('/category');
    }

    prices() {
        return this.web.get('/payout');
    }

    albums(category, limit, offset, city) {

        const params = new URLSearchParams();
        if (category !== 'all') {
            params.append('category', category);

        }
        if (city) {
            params.append('city_id', city);
        }
        params.append('limit', limit);
        params.append('offset', offset);

        return this.web.get('/albums', {
            params: params
        });
    }
}