<template>
    <div>
      <b-dropdown :text="getPlaceName() ? getPlaceName() : $t('base.search_placeholder')" class="form-dropdown" :class="cssClass">
        <b-dropdown-item  v-bind:key="city.id"  v-on:click="setPlace(city.raw)"
                         v-for="city in $store.state.list.cities">
          {{ city[$store.state.user.options.locale] ? city[$store.state.user.options.locale] : city['en'] }}</b-dropdown-item>
      </b-dropdown>
        <div class="clear" v-on:click="clear()"></div>
    </div>
</template>

<script>
import Api from "@/components/services";

export default {
    name: "CityDropdown",
    props: {
        cssClass:{
          type: String,
          default: ""
        },
        city:{
          type: String,
          default: ""
        }
    },
    data() {
        return {
           cities:[]
        }
    },
  mounted() {
    let api = new Api();
    api
        .cities(this.city)
        ?.then(() => {
          if (this.city && this?.$store?.state?.list?.cities[0]) {
            this.setPlace(this.$store.state.list.cities[0].raw);
          }
        });
  },
    watch: {},
    methods: {
      setPlace(raw) {
        this.$place.setRawData(raw);
      },
      clear() {
        this.$place.clear()
        this.$forceUpdate();
      },
      getPlaceName() {
        // return this.$place.getName();
        return this.$place.getCity()[this.$store.state.user.options.locale];
      },
      getRaw() {
        return this.$place.getRawData();
      }
    }
}
</script>

<style scoped>

</style>