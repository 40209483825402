<template>
  <div class="row">
    <div class="col-12 border-bottom col-md-6 border-md-right border-md-bottom-0">
      <datepicker
          v-model="selectedDate"
          class="vdp-datepicker-inline inverted"
          :inline="true"
          :disabledDates="calendarDisableDates(calendar)"
          :language="languages[$store.state.user.options.locale]"
          :fullMonthName="true"
          :mondayFirst="true">

      </datepicker>
    </div>
<!--    <time-calendar-->
<!--        :orders="dayOrders"-->
<!--        :non-working-hours="calendarNonWorkingHours"-->
<!--    ></time-calendar>-->
  </div>
</template>

<script>
import TimeCalendar from "./TimeCalendar";
import datepicker from 'vuejs-datepicker';
import moment from 'moment';
import ProfileApi from '../profile/service';

let api = undefined;
export default {
  name: "CalendarBlock",
  props: {
    photographer: {
      type: Object
    },
    languages: {
      type: undefined
    },

    calendar: {
      type: Object
    }
  },
  data() {
    return {
      selectedDate: new Date(),
      dayOrders: [],
      orders: [],
      calendarNonWorkingHours: [],
      nonWorkingHours: [],
    }
  },
  methods: {
    //TODO неправильно ибо запрашиваю в нескольких местах
    // async getTown() {
    //     if (this.$store.state.user.place) {
    //         // if (false) {
    //         let search = {};
    //         search.city = this.$store.state.user.dadata.data.city;
    //         search.region = this.$store.state.user.dadata.data.region;
    //         search.country = this.$store.state.user.dadata.data.country;
    //         return await api
    //             .search(search)
    //             .then((data) => {
    //                 return data.data.data;
    //             });
    //
    //     } else {
    //         let data = this.$store.state.user.options.city;
    //         return new Promise(function (resolve) {
    //             resolve(data);
    //         });
    //     }
    // },
    loadOrders(date) {
      // this.getTown().then((city) => {
      api
          .getOrders(this.photographer.id, moment(date), this.$place.getCity().id)
          .then((res) => {
            this.orders = res.data.data.orders;
            this.sortingOrders();
            if (res.data.data.nonWorkingHours) {
              this.nonWorkingHours = res.data.data.nonWorkingHours;
              this.fetchNonWorkHours(date);
            }
          })
      // });
    },
    fetchNonWorkHours(date) {
      this.calendarNonWorkingHours = [];
      let formattedDate = moment(date).format('YYYY-MM-DD')
      this.nonWorkingHours.forEach((hour) => {
        let start = moment(hour.date).format('YYYY-MM-DD');
        if (start === formattedDate) {
          let date = moment(hour.date);
          let start = moment(date).toDate();
          let end = moment(date).toDate();
          start.setHours(hour.start.split(':')[0]);
          start.setMinutes(hour.start.split(':')[1]);
          end.setHours(hour.end.split(':')[0]);
          end.setMinutes(hour.end.split(':')[1]);
          //формат бэка неудачный
          this.calendarNonWorkingHours.push(
              {
                id: hour.id,
                start: start,
                end: end
              }
          );
          // this.calendarNonWorkingHours.push(hour);
        }
      });
    },
  sortingOrders() {
    this.dayOrders = [];
    this.orders.forEach((order) => {
      let start = moment(order.start_date).format('YYYY-MM-DD');
      let selected = moment(this.selectedDate).format('YYYY-MM-DD');
      if (start === selected) {
        this.dayOrders.push(order);
      }
    });
  }
}
,
computed: {
  disableDates: function () {
    if (this.calendar.is_main === true) {
      let dates = [];
      this.calendar.calendar.forEach(calendar => dates.push(moment(calendar.date).toDate()));
      return {
        to: moment().add(-1, 'day').endOf("day").toDate(),
        dates: dates
      }
    } else {
      let leftDate = moment();
      let rightDate = moment();
      let lastDate = moment();
      let ranges = [];
      let lastInCalendar = this.calendar.calendar[this.calendar.calendar.length - 1];
      this.calendar.calendar.forEach((calendar) => {
        rightDate = moment(calendar.date);
        if (rightDate.diff(leftDate, 'days') >= 1) {

          ranges.push(
              {
                from: leftDate.clone(),
                to: rightDate,
              }
          );

        }
        leftDate = rightDate.clone().add(1, 'day');
        if (calendar === lastInCalendar) {
          lastDate = moment(leftDate).toDate();
        }
      });

      return {
        to: moment().add(-1, 'day').endOf("day").toDate(),
        from: lastDate,
        ranges: ranges
      }
    }

  }
}
,
watch: {
  selectedDate: function (value, oldValue) {
    if (moment(value).format('MM') !== moment(oldValue).format('MM')) {
      this.loadOrders(value);
    } else {
      this.sortingOrders();
      this.fetchNonWorkHours(value);
    }
  }
}
,
mounted()
{
  api = new ProfileApi();
  this.loadOrders(moment());
}
,
components: {
  TimeCalendar, datepicker
}
}
</script>

<style scoped>

</style>