<template>
  <div class="main-content flex-shrink-0">
    <reset-password></reset-password>
    <calendar-modal></calendar-modal>
    <div class="front-promo d-flex align-items-end">
      <div class="bg bg-bali"></div>
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-xl-10">
            <p class="title" ><span>Твой фотограф</span> <br/>на Бали здесь!</p>
            <p class="subtitle">{{ $t('base.notice_2') }}</p>
            <div class="form-search">
              <div class="form-row align-items-center justify-content-center">
                <div class="col-7 col-lg form-group">
                  <div class="search-address-block">
                    <CityDropdown ref="place" city="ChIJ06f8IHUv0i0RhM1WxCy2cig"></CityDropdown>
                  </div>
                </div>
                <div class="col-7 col-lg-auto form-group">
                  <button class="btn btn-white btn-lg btn-block btn-inline-icon icon-calendar"
                          v-on:click="$bvModal.show('modal-search-calendar')">
                    <span v-if="searchParams.start_date">{{searchParams.start_date | moment('DD.MM.YYYY')}}</span>
                    <span v-else>{{  $t('lists.main_time-choose.select') }}</span>
                  </button>
                </div>
<!--                <div class="col-5 col-lg-auto form-group">-->
<!--                  <button class="btn btn-white btn-lg btn-block btn-inline-icon icon-time" :disabled="true"-->
<!--                          v-on:click="goToSearch(true)">{{ $t('base.now') }}-->
<!--                  </button>-->
<!--                </div>-->
                <div class="col-7 col-lg-2 form-group">
                  <div
                      class="btn btn-primary btn-lg btn-block btn-uppercase"
                      v-if="$place.getCity() && searchParams.start_date"
                      v-on:click="goToSearch(false)">{{ $t('base.search') }}
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg btn-block btn-uppercase" v-else :disabled="true">
                    {{ $t('base.search') }}
                  </button>
                </div>
              </div>
            </div>
            <ul class="nav-social no-list light d-flex align-items-center justify-content-center">
              <li><a href="https://t.me/snappy_me" target="_blank" rel="nofollow">telegram</a></li>
              <li><a href="https://wa.me/" target="_blank" rel="nofollow">whatsapp</a></li>
              <li><a href="https://www.youtube.com/channel/UCX-SfQrRNA-GShfR99t63Kw/" target="_blank" rel="nofollow">youtube</a></li>
              <!--li><a href="https://www.instagram.com/snappy_photoshoot/" target="_blank" rel="nofollow">instagram</a></li-->
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="photographers-slider">
      <div class="container">
        <p class="h1">{{ $t('base.photographers') }}</p>
        <p class="lead">Свободное время, стоимость и портфолио доступны сразу на карточке фотографа. Бронируй за пару кликов!</p>
        <carousel :nav="true" :navText="['prev','next']" :loop="false" :autoplay="false" :autoplaySpeed="2500" :margin="20"
                  :responsive="{0:{items:1,dots:true},768:{items:2,dots:true},1200:{items:3,dots:false}}"
                  v-if="photographers.length > 0">
          <div class="photographer-item" v-bind:key="photographer.user.id" v-for="photographer in photographers">
            <div class="d-flex flex-column">
              <div class="photographer-item-slider">
                <carousel v-if="timer==0" :navText="['prev','next']" :loop="true" :items="1" :nav="true" :dots="true" :autoplay="false" :autoplayTimeout="3000">
                  <div class="slide" :style="{backgroundImage: `url(${header.path.profile})`}" v-for="header in photographer.headers"></div>
                </carousel>
              </div>
              <div class="d-flex flex-column w-100">
                <div class="d-flex align-items-center user">
                  <div>
                    <div class="avatar" :style="{backgroundImage: `url(${photographer.user.avatars['thumb-166']})`}"></div>
                  </div>
                  <div class="name">
                    <div>{{ photographer.user.name }}</div>
                  </div>
                </div>
                <div class="info" v-if="photographer.user.equipment">
                  <div class="row justify-content-between">
                    <div class="col-12 col-md-12">
                      <div class="camera">{{ $t('lists.equipment.' + photographer.user.equipment[0]) }}</div>
                    </div>
                  </div>
                </div>
                <ul class="tags no-list d-none d-md-block">
                  <li v-for="service in photographer.user.additional_services">{{ $t('lists.additional_services.' + service) }}</li>
                </ul>
                <div class="photographer-bottom d-flex mt-auto justify-content-between align-items-center mt-auto mt-auto">
                  <div>
                    <!--button class="btn btn-sm btn-primary btn-order" v-on:click="$root.$emit('create-order', user)">Заказать</button-->
                  </div>
                  <div v-if="photographer.profile.price > 0">
                    <div class="price text-right">
                      {{ photographer.profile.price | number('0,0', {thousandsSeparator: ' '}) }}
                      <span><i class="rub">Р</i></span>
                      <span>/ 1 час</span>
                    </div>
                    <div class="muted p-xs">30% оплата при брони</div>
                  </div>
                </div>
              </div>
            </div>
            <router-link :to="{name:'photographer_profile',params:{id:photographer.user.id}}" class="photographer-item-link"></router-link>
          </div>
        </carousel>
      </div>
    </div>

    <div class="front-about">
      <div class="container">
        <p class="h1">{{ $t('base.about_project') }}</p>
        <div class="lead">Мы проверили и пригласили фотографов с подтвержденным опытом на Бали</div>
        <div class="row">
          <div class="col-12 col-xl">
            <div class="about-card">
              <div class="icon" style="background-image: url(assets/images/icons/green/selfie.svg)"></div>
              <div class="info">
                <p class="title">Фотографы, знающие места на Бали</p>
                <div class="lead">
                    Предложат популярные или уникальные локации: водопады, скрытые пляжи, рисовые поля, скалы, виды на вулкан и т.д.
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl">
            <div class="about-card">
              <div class="icon" style="background-image: url(assets/images/icons/green/photo.svg)"></div>
              <div class="info">
                <p class="title">Уровни съемки от пляжа до свадебной</p>
                <div class="lead">
                  Фотографы помогут реализовать идеи. Выбери профи для фотосеста на пляже или сложной съемки свадебной церемонии с квадрокоптером
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl">
            <div class="about-card">
              <div class="icon" style="background-image: url(assets/images/icons/green/cam.svg)"></div>
              <div class="info">
                <p class="title" v-html="$t('base.notice_6')"></p>
                <div class="lead">
                  Привезли с Бали не только селфи. Бали создан для эмоций, а Snappy для того, чтобы вы их качесвенно сохраняли
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--div class="front-price">
      <div class="container">
        <p class="h1">{{ $t('base.price') }}</p>
        <div class="lead" v-html="$t('base.lead')"></div>
        <carousel :nav="false" :loop="true"
                  :responsive="{0:{items:1,dots:true},768:{items:2,dots:true},1200:{items:3,dots:true}}"
                  v-if="prices.length > 0">
          <div class="price-card card" :class="{best:price.is_best}" v-for="price in prices">
            <div class="card-header">
              <p class="title">{{ $t('base.prices.' + price.value + '.title') }}</p>
              <p class="price">{{ $t('base.prices.' + price.value + '.title_2') }}</p>
              <p class="price-for">{{ $t('base.prices.' + price.value + '.for') }}</p>
            </div>
            <div class="card-body">
              <ul class="no-list" v-html="$t('base.prices.' + price.value + '.params')"></ul>
            </div>
          </div>
        </carousel>
      </div>
    </div-->

    <div class="front-photos">
      <div class="container">
        <p class="h1">Посмотри примеры съемок</p>
        <div class="categories-filter">
          <div class="categories-filter-all">
            <div class="pointer" v-on:click="albumParams.activeCategory = 'all'"
                 :class="{active:albumParams.activeCategory === 'all'}">{{ $t('categories.all') }}
            </div>
          </div>
          <div class="categories">
            <ul class="no-list">
              <li v-for="category in categories">
                <div class="pointer"
                     :class="{active:albumParams.activeCategory === category}"
                     v-on:click="albumParams.activeCategory = category">{{ $t('categories.' + category) }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div v-if="albumParams.total === 0">В категории пока нет альбомов</div>
          <div class="col-12 col-md-4 col-xl-3" v-for="(album, index) in albums">
            <div class="photos-card" v-b-modal="'modal-album-'+album.id">
              <div class="photo" :style="{backgroundImage: `url(${album.images[0].path.medium_preview})`,}">
              </div>
              <!--div class="rating">{{album.personal.rating.avg}}</div-->
              <div class="author">{{ getName(album.personal) }}</div>
              <div class="title">{{ album.name }}</div>
            </div>
            <b-modal :id="'modal-album-'+album.id" size="xl" centered hide-header hide-footer>
              <div class="modal-header">
                <div class="modal-brand"></div>
                <div class="close" @click="$bvModal.hide('modal-album-'+album.id)"></div>
              </div>
              <div class="modal-xl-content album-gallery">
                <masonry-wall :items="album.images" :ssr-columns="1" :column-width="400" :gap="2" :min-columns="2" :max-columns="5">
                  <template #default="{ item, index }">
                    <div>
                      <img :src="item.path.resize_full_image" class="img-fluid">
                      <!--                          <h1>{{ item.title }} ({{ index }})</h1>-->
                      <!--                          <span>{{ item.description }}</span>-->
                    </div>
                  </template>
                </masonry-wall>
              </div>
              <div class="album-sharing">
                <div class="d-flex align-items-center justify-content-between">
                  <router-link :to="{name:'photographer_profile',params:{id:album.personal.id}}"
                               class="pointer user d-flex align-items-center">
                    <div>
                      <div class="avatar" :style="{
                                                    backgroundImage: `url(${album.personal.avatars['thumb-166']})`,
                                                    }"></div>
                    </div>
                    <div>
                      <p class="name">{{ getName(album.personal) }}</p>
                      <p class="comment">{{ album.images.length }} фотографий</p>
                    </div>
                  </router-link>
                  <div class="d-flex align-items-center">
                    <!--div>
                        <div class="share"></div>
                    </div-->
                    <!--                                                <div>-->
                    <!--                                                    <div class="btn btn-primary-light btn-icon icon-heart like">353</div>-->
                    <!--                                                </div>-->
                  </div>
                </div>
              </div>
            </b-modal>
          </div>

        </div>
        <div
            v-if="albumParams.offset +  albumParams.limit < albumParams.total"
            class="btn btn-secondary btn-lg btn-block btn-text-light btn-text-bigger btn-uppercase"
            v-on:click="albumParams.offset += albumParams.limit">{{ $t('base.show_more') }}
        </div>
      </div>
    </div>

    <div class="front-app">
      <div class="container">
        <div class="app-block">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
              <div class="title">{{ $t('base.app_market') }}</div>
              <div class="links">
                <a href="https://apps.apple.com/us/app/snappy-me/id6615084273" target="_blank" class="appstore">AppStore</a>
                <a href="https://play.google.com/store/apps/details?id=me.snappy.android" target="_blank" class="googleplay">Google Play</a>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <img src="assets/images/app.png" class="screen img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import MainApi from './services';
import ResetPassword from "../widgets/ResetPassword";
import Autocomplete from "../widgets/Autocomplete";
import moment from 'moment';
import Calendar from "@/components/modals/CalendarModal.vue";
import CalendarModal from "@/components/modals/CalendarModal.vue";
import CityDropdown from "@/components/widgets/CityDropdown.vue";

const api = new MainApi();
export default {
  name: 'MainPage',
  data() {
    return {
      photographers: [],
      categories: [],
      prices: [
        {
          'is_best': false,
          'min_price': 250,
          'value': 'agent'
        },
        {
          'is_best': true,
          'min_price': 500,
          'value': 'pro'
        },
        {
          'is_best': false,
          'min_price': 1000,
          'value': 'guru'
        }
      ],
      timer: 1,
      albums: [],
      timeChoose: [
        {text: 'select', value: 15, type: 'minutes'},
        {text: '15m', value: 15, type: 'minutes'},
        {text: '30m', value: 30, type: 'minutes'},
        {text: '45m', value: 45, type: 'minutes'},
      ],
      timeChooseChoice: {text: 'select', value: 15, type: 'minutes'},
      searchParams: {
        start_date: null,
      },
      gOptions: {
        language: 'en'
      },
      dadataObject: '',
      dadataObjectFull: undefined,
      albumParams: {
        is_first: true,
        activeCategory: 'all',
        limit: 5,
        minLimit: 5,
        limitMobile: 3,
        limitTable: 3,
        limitDesktop: 4,
        offset: 0,
        total: undefined
      },
      loadModules: {
        photographer: false,
        categories: false,
        //price: false,
        album: false,
      }
    };
  },
  components: {CityDropdown, CalendarModal, Calendar, Autocomplete, ResetPassword, carousel},
  watch: {

    "albumParams.offset": {
      handler: function (newValue) {
        if (newValue) {
          this.getAlbums();
        }
      },
      deep: true
    },
    "albumParams.activeCategory": {
      handler: function () {
        this.albums = [];
        this.albumParams.offset = 0;
        this.albumParams.is_first = true;
        this.albumParams.total = undefined;
        this.getAlbums();
      },
      deep: true
    },
    loadModules: {
      handler: function (newValue) {
        this.$root.$emit('load-modules', newValue);
      },
      deep: true
    }
  },
  methods: {
    setFast(time) {
      this.$refs.timeChoose.hide()
      this.timeChooseChoice = time;
      this.searchParams.start_date = moment().add(time.value, time.type);
    },
    getPhotographer() {
      api.photographer('ChIJ06f8IHUv0i0RhM1WxCy2cig')
      // api.photographer('ChIJXRwVCyW2SkERZd3Fn0JSGM8')
          .then((res) => {
            this.photographers = res.data.data;
            this.loadModules.photographer = true;
            var timerInterval = setInterval(() => {
              if (this.timer > 0) {
                this.timer = this.timer - 1;
              } else {
                clearInterval(timerInterval);
              }
            }, 150);
          });
    },
    getCategories() {
      api.lists()
          .then((res) => {
            this.categories = this.$store.state.list.categories;
            this.loadModules.categories = true;
          });
    },
    /*
            getPrices() {
                api.prices()
                    .then((res) => {
                        this.prices = res.data.data[this.$store.state.user.options.locale];
                        this.loadModules.price = true;
                    });
            },
    */
    getAlbums() {
      this.getSize();
      api.albums(this.albumParams.activeCategory, this.albumParams.limit, this.albumParams.offset, 'ChIJ06f8IHUv0i0RhM1WxCy2cig')
      // api.albums(this.albumParams.activeCategory, this.albumParams.limit, this.albumParams.offset, 'ChIJXRwVCyW2SkERZd3Fn0JSGM8')
          .then((res) => {
            res.data.data.albums.forEach((album) => {
              this.albums.push(album)
            })
            this.albumParams.total = res.data.data.total;
            this.loadModules.album = true;
          });
    },
    getSize() {
      let width = document.documentElement.clientWidth;
      if (width < 780) {
        if (this.albumParams.is_first) {
          this.albumParams.is_first = false;
          this.albumParams.limit = this.albumParams.minLimit;
        } else {
          this.albumParams.limit = this.albumParams.limitMobile;
        }
      } else if (width < 1200) {
        this.albumParams.limit = this.albumParams.limitTable;
      } else {
        this.albumParams.limit = this.albumParams.limitDesktop;
      }
    },
    goToSearch(isLiveSearch) {
      if (this.$metrika) {
        this.$metrika.reachGoal('Search');
      }
      var params = {};
      if (isLiveSearch) {
        params = {
          // start_date: this.searchParams.start_date.toDate(),
          show_filters: true
        };
      } else {
        if(!this.searchParams.start_date){
          return;
        }
        params = {
          start_date: this.searchParams.start_date.toDate(),
          show_filters: false
        };
      }

      this.$router.push({
        name: isLiveSearch ? 'live_search' : 'search', query: {
          city: this.$place.getName(),
          start_params: JSON.stringify(params)
        }
      });
    }
  },
  mounted() {
    // this.$refs.place.$on('place-enter', () => {
    //   this.goToSearch();
    // });
    this.$root.$on('reload-main', () => {
      this.$root.$emit('load-modules', this.loadModules);
    });
    this.$root.$on('update-options', () => {
      // this.$forceUpdate();
    });
    this.$root.$on('change_date', (date) => {
      this.searchParams.start_date = moment(date);
    });
    this.searchParams.start_date = moment();
    this.getPhotographer();
    this.getCategories();
    //this.getPrices();
    this.getAlbums();
    this.$showJivo();
  },
};
</script>
<style type="text/css" scoped>
@import "../../assets/css/forms.css";
@import "../../assets/css/modals.css";
@import "../../assets/css/owl.css";
</style>