<template>
  <div class="inner-content" v-if="user">
    <p class="h2">{{ $t('lk.personal') }}</p>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label>{{ $t('lk.personal_area.name') }}</label>
          <the-mask
              class="form-control form-control-sm"
              :tokens="{ '#': {pattern: /[A-zА-я]/}}"
              mask="####################"
              v-model="user.name" placeholder="Введите"></the-mask>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">

          <label>{{ $t('lk.personal_area.surname') }}</label>
          <the-mask
              class="form-control form-control-sm"
              :tokens="{ '#': {pattern: /[A-zА-я]/}}"
              mask="####################"
              v-model="user.surname" placeholder="Введите"></the-mask>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="isPhotographer()">
        <div class="form-group">
          <label>{{ $t('lk.personal_area.nickname') }}</label>
          <the-mask
              class="form-control form-control-sm"
              :tokens="{ '#': {pattern: /[A-zА-я0-9]/}}"
              mask="####################"
              v-model="user.nickname"
              placeholder="Введите"></the-mask>
        </div>
      </div>
    </div>
    <p class="form-text text-muted" v-if="isPhotographer()">{{ $t('lk.personal_area.info') }}</p>
    <p class="form-text text-muted" v-if="!isPhotographer()">{{ $t('lk.personal_area.info_client') }}</p>

    <div class="account-separator"></div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label>{{ $t('lk.personal_area.birthday') }}</label>
          <datepicker class=""
                      inputClass="form-control form-control-sm form-control-datepicker"
                      placeholder="Выберите"
                      :language="languages[$store.state.user.options.locale]"
                      :fullMonthName="true"
                      v-model="user.birth_date"
                      format="yyyy-MM-dd"
                      :mondayFirst="true"></datepicker>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="user.sex">
        <div class="form-group">
          <label>{{ $t('lk.personal_area.sex') }}</label>

          <b-dropdown no-flip :text="$t('lk.personal_area.'+user.sex)" class="form-dropdown form-dropdown-sm">
            <b-dd-text>
              <div class="dropdown-item" :class="{active:user.sex === 'male'}" v-on:click="user.sex = 'male'">
                {{ $t('lk.personal_area.male') }}
              </div>
            </b-dd-text>
            <b-dd-text>
              <div class="dropdown-item" :class="{active:user.sex === 'female'}" v-on:click="user.sex = 'female'">
                {{ $t('lk.personal_area.female') }}
              </div>
            </b-dd-text>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="account-separator line"></div>

    <p class="h2">{{ $t('lk.personal_area.contact') }}</p>
    <div class="form-group">
      <label>{{ $t('lk.personal_area.email') }}</label>
      <div class="input-group">
        <input type="email" :class="{ 'has-error' :!isEmailValid()}" class="form-control form-control-sm"
               placeholder="your@email.com" v-model="user.email">
      </div>
      <p class="form-text text-muted">{{ $t('lk.personal_area.info_2') }}</p>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label>{{ $t('lk.personal_area.phone') }}</label>

          <div class="input-group">
            <vue-tel-input ref="tel" wrapperClasses="form-control-phone" inputClasses="form-control form-control-sm"
                           v-model="user.phone" :valid-characters-only="true"
                           :dynamicPlaceholder="true"></vue-tel-input>
            <!--                        <the-mask-->
            <!--                                class="form-control form-control-sm"-->
            <!--                                :tokens="{ '#': {pattern: /[0-9]/}}"-->
            <!--                                mask="+# (###) ### ## ##"-->
            <!--                                v-model="user.phone" placeholder="+7 (000) 000-00-00"></the-mask>-->
            <div class="input-group-append">
              <span class="input-group-text pointer"
                    v-on:click="user.phone = ''">{{ $t('lk.personal_area.delete') }}</span>
            </div>
          </div>
          <p class="form-text text-muted">{{ $t('lk.personal_area.notification_phone') }}</p>
          <p class="form-text text-muted" v-if="!user.is_phone_confirmed">Чтобы получать уведомления в Telegram,
            сохраните ваш телефон.</p>
          <div v-if="user.is_phone_confirmed && !user.is_use_messenger">
            <p class="form-text text-muted">Чтобы получать уведомления в Telegram, подпишитесь на нашего бота с вашим
              основным номером телефона.</p>
            <a target="_blank" :href="messenger_link" class="btn btn-sm btn-primary">Подписаться на бота</a>
          </div>
          <p class="form-text text-muted" v-if="user.is_phone_confirmed && user.is_use_messenger">Вы получаете
            уведомления в Telegram.</p>
        </div>
      </div>
      <!--            <div class="col-12 col-md-6">-->
      <!--                <div class="form-group">-->
      <!--                    <label>{{$t('lk.personal_area.additional_phone')}}</label>-->
      <!--                    <div class="input-group">-->
      <!--                        <div class="input-group-prepend">-->
      <!--                            <div class="input-group-text">-->
      <!--&lt;!&ndash;                                <div class="flag flag-rus"></div>&ndash;&gt;-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                        <the-mask-->
      <!--                                class="form-control form-control-sm"-->
      <!--                                v-model="user.additional_phone" placeholder="+7 (000) 000-00-00"></the-mask>-->
      <!--                        <div class="input-group-append">-->
      <!--                            <span  class="input-group-text pointer" v-on:click="user.additional_phone = ''">{{$t('lk.personal_area.delete')}}</span>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <div class="account-separator line"></div>

    <div v-if="isPhotographer()">
      <p class="h2">{{ $t('lk.notification_area.accounts') }}</p>
      <p class="text-muted">{{ $t('lk.notification_area.subtitle') }}</p>
      <div class="row">
        <div class="col-12 col-md-6">
          <p class="p-md weight-500">{{ $t('lk.notification_area.instagram') }}</p>
          <!--p class="text-muted">{{ $t('lk.notification_area.instagram_info') }}</p-->
        </div>
        <div class="col-6 col-md-6">
          <input type="text" class="form-control form-control-sm"
                 :placeholder="$t('lk.notification_area.instagram_placeholder')">
        </div>
      </div>
      <div class="account-separator-sm"></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <p class="p-md weight-500">{{ $t('lk.notification_area.telegram') }}</p>
          <!--p class="text-muted">{{ $t('lk.notification_area.telegram_info') }}</p-->
        </div>
        <div class="col-6 col-md-6">
          <input type="text" class="form-control form-control-sm"
                 :placeholder="$t('lk.notification_area.telegram_placeholder')">
        </div>
      </div>
      <div class="account-separator-sm"></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <p class="p-md weight-500">{{ $t('lk.notification_area.website') }}</p>
          <!--p class="text-muted">{{ $t('lk.notification_area.website_info') }}</p-->
        </div>
        <div class="col-6 col-md-6">
          <input type="text" class="form-control form-control-sm"
                 :placeholder="$t('lk.notification_area.website_placeholder')">
        </div>
      </div>
      <div class="account-separator line"></div>
    </div>

    <p class="h2">{{ $t('lk.personal_area.profile_lang') }}</p>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label>{{ $t('lk.personal_area.your_lang') }}</label>
          <b-dropdown no-flip
                      :text="user.language ? $t('languages.'+user.language) : $t('lk.profile_area.choose_options')"
                      class="form-dropdown form-dropdown-sm">
            <b-dd-text v-for="lang in ['ru','en']">
              <div class="dropdown-checkbox" :class="{active:user.language === lang }"
                   v-on:click="user.language = lang">
                {{ $t('languages.' + lang) }}<br>
              </div>
            </b-dd-text>

          </b-dropdown>
          <p class="form-text text-muted">{{ $t('lk.personal_area.info_3') }}</p>
        </div>
      </div>
    </div>

    <div class="account-separator"></div>
    <fixed-button-panel ref="buttons">
      <div class="col-12 col-md-6">
        <div class="form-group">
              <span href="#"
                    class="btn btn-secondary btn-block btn-uppercase" v-on:click="load()">{{ $t('lk.personal_area.cancel') }}</span>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
<!--          :class="{'disabled' : isEqual(user, old_user)}"-->
              <span class="btn btn-primary btn-block btn-uppercase pointer "
                    v-on:click="save()">{{ $t('lk.personal_area.save') }}</span>
        </div>
      </div>
    </fixed-button-panel>
    <confirm-phone ref="confirmPhone"></confirm-phone>
  </div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import * as lang from "vuejs-datepicker/src/locale";
import Api from '../../service';
import {TheMask} from 'vue-the-mask'
import moment from 'moment'
import ConfirmPhone from "@/components/lk/components/widget/ConfirmPhone";
import FixedButtonPanel from "@/components/lk/components/widget/FixedButtonPanel";

let api = undefined;

export default {
  name: "Personal",
  components: {FixedButtonPanel, ConfirmPhone, datepicker, TheMask},
  data() {
    return {
      test: {},
      languages: lang,
      messenger_link: process.env.VUE_APP_TELEGRAM_BOT_LINK,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      old_user: {
        surname: "",
        name: "",
        nickname: "",
        phones: "",
        email: "",
        sex: "",
        birth_date: "",
        language: "",
      },
      user: {
        surname: "",
        name: "",
        nickname: "",
        phones: "",
        email: "",
        sex: "",
        birth_date: "",
        language: "",
      },
      loadModules: {
        personal: false,
      }
    }
  },
  watch: {
    "user.birth_date": {
      handler: function (newValue, oldVal) {
        if (newValue && newValue !== oldVal) {
          this.user.birth_date = moment(newValue).format('YYYY-MM-DD');
        }
      },
      // deep: true
    },
    loadModules: {
      handler: function (newValue) {

        this.$root.$emit('load-modules', newValue);
      },
      deep: true
    }
  },
  methods: {
    onUpdate(payload) {
      this.results = payload
    },
    load() {
      api
          .getPersonal()
          .then((res) => {
            this.user = this.clone(res.data.data.personal);
            this.old_user = this.clone(res.data.data.personal);
            this.loadModules.personal = true;
            this.$refs.buttons.$emit('load');
          })
          .catch((error) => {
            this.$root.$emit('error', error.response.data.message);
            this.loadModules.personal = true;
          })
    },
    isEmailValid: function () {
      return (this.user.email === "") ? false : this.reg.test(this.user.email);
    },
    isEqual(first, second) {
      return JSON.stringify(first) === JSON.stringify(second);
    },
    save() {
      // if (this.isEqual(this.user, this.old_user)) {
      //   return;
      // }
      if (this.isEmailValid && (this.phone !== '' && this.$refs.tel.phoneObject.isValid)) {
        api
            .updatePersonal(this.user)
            .then((res) => {
              this.$root.$emit('successful', 'Сохранено');
              this.old_user = this.clone(this.user);
              if (res.data.data.phone_confirmation && res.data.data.phone_confirmation.is_confirm === false) {
                this.$refs.confirmPhone.show(
                    {
                      timer: res.data.data.phone_confirmation.resend_life_time
                    }
                );
              }
            })
            .catch((error) => {
              this.$root.$emit('error', error.response.data.message);
            })
      }

    }
  },
  mounted() {

    api = new Api();
    this.$root.$on('update-options', () => {
      this.$forceUpdate();
    });
    this.load();


  },
  props: {
    // data:{
    //     type: Object,
    //     // required: true
    // }
  }
}
</script>

<style scoped>

</style>