<template>
<div>
 <cloudpayment-form ref="cloudpayments" :payment-config="config.widget" v-if="instrument === 'cloudpayments'"></cloudpayment-form>
</div>
</template>
<script>
import CloudpaymentForm from "@/components/payment/CloudpaymentForm.vue";

export default {
  name: "PaymentForm",
  components: {CloudpaymentForm},
  props:{
    config:{
      type:Object,
      default: ()=>{
        return {
          widget: {},
          instrument: '',
        }
      }
    },
  },
  data(){
    return {
      instrument: ''
    }
  },
  watch:{
    config: {
      handler: function (newConfig, oldVal) {
        if(newConfig.instrument){
          this.instrument = newConfig.instrument;
          setTimeout(()=>{
            this.$refs[this.instrument].pay();
          },2000);
        }
      },
      deep: true
    },
  },
  mounted() {
    if(this.config){
      this.initPay(this.config)
    }
  },
  methods:{
    initPay(newConfig){
      if(newConfig.instrument){
        this.instrument = newConfig.instrument;
        setTimeout(()=>{
          this.$refs[this.instrument].pay();
        },2000);
      }
    }
  }
}
</script>
<style scoped>

</style>