<template>
  <div class="main-content main-content-search flex-shrink-0">
    <ChangePhone></ChangePhone>
    <OrderInfoModal></OrderInfoModal>
    <PaymentForm :config="paymentDetails" v-if="paymentDetails.type === 'form'"></PaymentForm>
    <div class="filter">
      <div class="container-fluid">
        <div class="d-flex align-items-center">
          <div>
            <div class="form-inline">
              <div class="form-group d-none d-md-block" >
                <b-dropdown ref="timeSelector" no-flip v-if="!with_map"
                            class="form-dropdown form-dropdown-sm form-dropdown-with-label">
                  <template v-slot:button-content>
                    <div class="label">{{ $t('search.filter.shoot_time') }}</div>

                    <div class="value">{{ getDate() }} <span v-if="false">{{ getTime().HH + ':' + getTime().mm }}</span></div>
                  </template>
<!--                  <b-dd-text v-if="with_map">-->
<!--                    <div class="dropdown-form-group">-->
<!--                      <div class="form-row justify-content-between">-->
<!--                        <div class="col-auto">-->
<!--                          <label>{{ $t('search.filter.shoot_time_label') }}</label>-->
<!--                        </div>-->
<!--                        <div class="col-auto">-->
<!--                          <label class="muted">{{ $t('search.filter.shoot_time_comment') }}</label>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <ul class="time-choose no-list">-->
<!--                        <li :class="{active:isFast(time)}" v-for="time in timeChoose" v-on:click="setFast(time)">-->
<!--                          {{ $t('lists.time-choose.' + time.text) }}-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </b-dd-text>-->
                  <b-dd-text>
                    <datepicker
                        class="vdp-datepicker-inline"
                        :inline="true"
                        v-model="searchParams.start_date"
                        :disabledDates="disableDates"
                        :language="languages[$store.state.user.options.locale]"
                        :fullMonthName="true"
                        :mondayFirst="true">
                    </datepicker>
                  </b-dd-text>
                  <b-dd-text>
                    <div class="dropdown-form-group" v-if="with_map">
                      <div class="form-group">
                        <div class="form-row justify-content-between">
                          <div class="col-auto">
                            <label>{{ $t('search.filter.shoot_time_label2') }}</label>
                          </div>
                          <div class="col-auto">
                            <label class="muted">{{ $t('search.filter.shoot_time_comment2') }}</label>
                          </div>
                        </div>
                        <div class="form-control-count">
                          <vue-timepicker :value="getTime()" @change="changeTime" format="HH:mm" class="time-picker-sm"
                                          hide-clear-button></vue-timepicker>
                        </div>
                      </div>
                      <p class="comment">{{ $t('search.filter.shoot_time_text2') }}</p>
                    </div >
                  </b-dd-text>
                </b-dropdown>
              </div>
<!--              <div class="form-group d-none d-xxl-block">-->
<!--                <b-dropdown no-flip class="form-dropdown form-dropdown-sm form-dropdown-with-label">-->
<!--                  <template v-slot:button-content>-->
<!--                    <div class="label">{{ $t('search.filter.shoot_class') }}</div>-->
<!--                    <div class="value">{{ getServices(searchParams.photosession_type, 'photosession_types') }}</div>-->
<!--                  </template>-->
<!--                  <b-dd-text v-bind:key="type" v-for="(sum,type) in prices.min">-->
<!--                    <div class="dropdown-checkbox"-->
<!--                         :class="{active:searchParams.photosession_type.indexOf(type) > -1 }"-->
<!--                         v-on:click="setData('photosession_type', type)">-->
<!--                      {{ $t('lists.photosession_types.' + type) }}<br>-->
<!--                      <span class="comment">{{ $t('search.filter.shoot_class_from') }} {{-->
<!--                          sum-->
<!--                        }} {{ $t('search.filter.shoot_class_after') }}</span>-->
<!--                    </div>-->
<!--                  </b-dd-text>-->
<!--                </b-dropdown>-->
<!--              </div>-->
              <div class="form-group d-none d-xxl-block">
                <b-dropdown no-flip class="form-dropdown form-dropdown-sm form-dropdown-with-label">
                  <template v-slot:button-content>
                    <div class="label">{{ $t('search.filter.shoot_class') }}</div>
                    <div class="value">{{ getServices(searchParams.photographer_profession, 'photographer_profession') }}</div>
                  </template>
                  <b-dd-text v-bind:key="type" v-for="(sum,type) in prices.min">
                    <div class="dropdown-checkbox"
                         :class="{active:searchParams.photographer_profession.indexOf(type) > -1 }"
                         v-on:click="setData('photographer_profession', type)">
                      {{ $t('lists.photographer_profession.' + type) }}<br>
                      <span class="comment">{{ $t('search.filter.shoot_class_from') }} {{
                          sum
                        }} {{ $t('search.filter.shoot_class_after') }}</span>
                    </div>
                  </b-dd-text>
                </b-dropdown>
              </div>
              <div class="form-group d-none d-xl-block">
                <b-dropdown no-flip
                            class="form-dropdown form-dropdown-sm form-dropdown-with-label">
                  <template v-slot:button-content>
                    <div class="label">{{ $t('search.filter.duration') }}</div>
                    <div class="value">{{ getDuration() }}</div>
                  </template>
                  <b-dd-text>
                    <div class="dropdown-form-group">
                      <div class="form-group">
                        <div class="form-row justify-content-between">
                          <div class="col-auto">
                            <label>{{ $t('search.filter.duration') }}</label>
                          </div>
                          <div class="col-auto">
                            <label class="muted">Интервал 15 минут</label>
                          </div>
                        </div>
                        <div class="form-control-count">
                          <input type="text" class="form-control form-control-md" :value="getDuration()"
                                 :disabled="true">
                          <div class="plus" v-on:click="changeDuration(1)"></div>
                          <div class="minus" v-on:click="changeDuration(-1)"></div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Быстрый выбор</label>
                        <ul class="time-choose no-list">
                          <li :class="{active:isFastDurations(time) }" v-for="time in durationChoose"
                              v-on:click="setFastDuration(time)"> {{ $t('lists.time-choose.' + time.text) }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-dd-text>
                </b-dropdown>
              </div>
              <div class="form-group d-none d-md-block">
                <b-dropdown no-flip
                            class="form-dropdown form-dropdown-sm form-dropdown-with-label">
                  <template v-slot:button-content>
                    <div class="label">{{ $t('search.filter.price') }}</div>
                    <div class="value">{{ price.range[0] | number('0,0', {thousandsSeparator: ' '}) }} –
                      {{ price.range[1] | number('0,0', {thousandsSeparator: ' '}) }}
                      <i class="rub">Р</i></div>
                  </template>
                  <b-dd-text>
                    <div class="dropdown-form-group">
                      <p><strong>{{ $t('search.filter.price') }}</strong></p>
                      <p class="comment">{{ $t('search.filter.price_comment') }}</p>
                      <p class="price-filter-val">{{ price.range[0] | number('0,0', {thousandsSeparator: ' '}) }}
                        <i class="rub">Р</i> – {{ price.range[1] | number('0,0', {thousandsSeparator: ' '}) }} <i
                            class="rub">Р</i></p>
                      <vue-slider v-if="price.ready" @drag-end="changePriceRange()" v-model="price.range"
                                  :min="price.min" :max="price.max" :interval="price.step" :tooltip="'none'"/>
                    </div>
                  </b-dd-text>
                </b-dropdown>
              </div>
<!--              <div class="form-group d-none d-lg-block">-->
<!--                <div class="custom-control custom-checkbox-block">-->
<!--                  <input type="checkbox" name="checkbox" id="checkbox-distance" class="custom-control-input">-->
<!--                  <label for="checkbox-distance" class="custom-control-label">{{ $t('search.filter.distance') }}</label>-->
<!--                </div>-->
<!--              </div>-->
              <div class="form-group d-none d-xl-block">
                <button class="btn btn-md btn-text-medium btn-secondary" v-b-modal="'modal-filter-params'">
                  {{ $t('search.filter.more') }}
                </button>
              </div>
            </div>
          </div>
          <div class="ml-auto d-none d-md-block">
            <div v-if="isLiveSearch"
                class="custom-control custom-switch custom-switch-grey custom-switch-right d-none d-xl-block map-switcher">
              <input type="checkbox" class="custom-control-input" id="show-map-switcher" v-model="with_map">
              <label class="custom-control-label nowrap" for="show-map-switcher" v-on:click="with_map !== with_map">Показать
                карту</label>
            </div>
            <div class="d-xl-none">
              <button class="btn btn-primary btn-md btn-text-medium" v-b-modal="'modal-filter-params'">Все фильтры
              </button>
            </div>
          </div>
          <button class="btn btn-primary btn-sm btn-text-medium btn-block d-md-none" v-b-modal="'modal-filter-params'">
            Фильтр
          </button>
        </div>
      </div>
    </div>
    <b-modal id="modal-filter-params" centered hide-header hide-footer>
      <div class="modal-header border-bottom">
        <p class="modal-title modal-title-sm">{{ $t('search.filter.title') }}</p>
        <div class="close" @click="$bvModal.hide('modal-filter-params')"></div>
      </div>
      <div class="form-group d-xl-none">
        <div class="form-row justify-content-between">
          <div class="col-auto">
            <label class="p-md weight-700">{{ $t('search.filter.shoot_time_label') }}</label>
          </div>
          <div class="col-auto">
            <label class="muted">{{ $t('search.filter.shoot_time_comment') }}</label>
          </div>
        </div>
        <ul class="time-choose no-list">
          <li :class="{active:isFast(time) }" v-for="time in timeChoose" v-on:click="setFast(time)">
            {{ $t('lists.time-choose.' + time.text) }}
          </li>
        </ul>
      </div>
      <div class="form-group d-xl-none">
        <datepicker
            class="vdp-datepicker-inline no-padding"
            :inline="true"
            v-model="searchParams.start_date"
            :disabledDates="disableDates"
            :language="languages[$store.state.user.options.locale]"
            :fullMonthName="true"
            :mondayFirst="true">
        </datepicker>
      </div>
      <div class="form-group d-xl-none">
        <div class="form-group">
          <div class="form-row justify-content-between">
            <div class="col-auto">
              <label class="p-md weight-700">{{ $t('search.filter.shoot_time_label2') }}</label>
            </div>
            <div class="col-auto">
              <label class="muted">{{ $t('search.filter.shoot_time_comment2') }}</label>
            </div>
          </div>
          <div class="form-control-count">
            <vue-timepicker :value="getTime()" @change="changeTime" format="HH:mm" hide-clear-button></vue-timepicker>
          </div>
        </div>
        <p class="comment p-sm mb-3">{{ $t('search.filter.shoot_time_text2') }}</p>
        <hr>
      </div>
      <div class="form-group d-none d-xl-block">
        <label class="p-md weight-700">Человек</label>
        <!--                TODO чет какойто говно код надо поправить-->
        <b-dropdown no-flip
                    :text="$t('lists.people_count.' + order_data.order.count_people)"
                    class="form-dropdown form-dropdown-md">
          <b-dd-text v-for="counters in $store.state.list.people_counter">
            <div class="dropdown-item" :class="{active:order_data.order.count_people === counters}"
                 v-on:click="order_data.order.count_people = counters"> {{ $t('lists.people_count.' + counters) }}
            </div>
          </b-dd-text>
        </b-dropdown>
      </div>
      <div class="form-group d-none d-xl-block">
        <label class="p-md weight-700">Оборудование</label>
        <b-dropdown no-flip :text="getServices(this.searchParams.equipment, 'equipment')" class="form-dropdown">
          <b-dd-text v-bind:key="equipment" v-for="equipment in $store.state.list.equipment">
            <div class="dropdown-checkbox"
                 :class="{active:searchParams.equipment.indexOf(equipment) > -1 }"
                 v-on:click="setData('equipment', equipment)">
              {{ $t('lists.equipment.' + equipment) }}<br>
            </div>
          </b-dd-text>
        </b-dropdown>
      </div>
      <div class="form-group d-none d-xl-block">
        <label class="p-md weight-700">Услуги</label>
        <b-dropdown no-flip :text="getServices(searchParams.services, 'additional_services')" class="form-dropdown">
          <b-dd-text v-bind:key="additional_services"
                     v-for="additional_services in $store.state.list.additional_services">
            <div class="dropdown-checkbox"
                 :class="{active:searchParams.services.indexOf(additional_services) > -1 }"
                 v-on:click="setData('services', additional_services)">
              {{ $t('lists.additional_services.' + additional_services) }}<br>
            </div>
          </b-dd-text>
        </b-dropdown>
      </div>
      <div class="form-group d-none d-xl-block">
        <label class="p-md weight-700">Локация съемки</label>
        <b-dropdown no-flip :text="getServices(searchParams.locations, 'locations')" class="form-dropdown">
          <b-dd-text v-bind:key="location" v-for="location in $store.state.list.locations">
            <div class="dropdown-checkbox"
                 :class="{active:searchParams.locations.indexOf(location) > -1 }"
                 v-on:click="setData('locations', location)">
              {{ $t('lists.locations.' + location) }}<br>
            </div>
          </b-dd-text>
        </b-dropdown>
        <hr>
      </div>
      <div class="form-group d-xl-none">
        <div class="form-group">
          <label class="p-md weight-700">{{ $t('search.filter.duration') }}</label>
          <p class="comment p-sm mb-3">{{ $t('search.filter.duration_comment') }}</p>
          <div class="form-control-count">
            <input type="text" class="form-control form-control-md" :value="getDuration()" :disabled="true">
            <div class="plus" v-on:click="changeDuration(1)"></div>
            <div class="minus" v-on:click="changeDuration(-1)"></div>
          </div>
        </div>
        <div class="form-group">
          <label>Быстрый выбор</label>
          <ul class="time-choose no-list">
            <li :class="{active:isFastDurations(time) }" v-for="time in durationChoose"
                v-on:click="setFastDuration(time)">{{ $t('lists.time-choose.' + time.text) }}
            </li>
          </ul>
        </div>
        <hr>
      </div>
      <div class="form-group d-xl-none">
        <label class="p-md weight-700">{{ $t('search.filter.price') }}</label>
        <p class="comment p-sm mb-3">{{ $t('search.filter.price_comment') }}</p>
        <p class="price-filter-val">{{ price.range[0] | number('0,0', {thousandsSeparator: ' '}) }}
          <i class="rub">Р</i> – {{ price.range[1] | number('0,0', {thousandsSeparator: ' '}) }} <i class="rub">Р</i>
        </p>
        <vue-slider @drag-end="changePriceRange()" v-if="price.ready" v-model="price.range" :min="price.min"
                    :max="price.max" :interval="price.step" :tooltip="'none'"/>
        <hr>
      </div>
      <div class="form-group d-xxl-none">
        <label class="p-md weight-700">{{ $t('search.filter.shoot_class') }}</label>
        <div class="form-dropdown">
          <!--                    <div-->
          <!--                            v-for="(sum,key) in prices.min"-->
          <!--                            class="dropdown-radio dropdown-radio-inline"-->
          <!--                            :class="{active:searchParams.photosession_type === key}"-->
          <!--                            v-on:click="setPhotoSessionType(key)"-->
          <!--                    >-->
          <!--                        {{ $t('lists.photosession_types.' + key) }} <br>-->
          <!--                        <span class="comment">{{ $t('search.filter.shoot_class_from') }} {{ sum }} {{ $t('search.filter.shoot_class_after') }}</span>-->
          <!--                    </div>-->
<!--          <b-dropdown no-flip :text="getServices(searchParams.photosession_type, 'photosession_types')"-->
<!--                      class="form-dropdown">-->
<!--            <b-dd-text v-bind:key="type" v-for="(sum,type) in prices.min">-->
<!--              <div class="dropdown-checkbox"-->
<!--                   :class="{active:searchParams.photosession_type.indexOf(type) > -1 }"-->
<!--                   v-on:click="setData('photosession_type', type)">-->
<!--                {{ $t('lists.photosession_types.' + type) }}<br>-->
<!--              </div>-->
<!--            </b-dd-text>-->
<!--          </b-dropdown>-->
          <b-dropdown no-flip :text="getServices(searchParams.photographer_profession, 'photographer_profession')"
                      class="form-dropdown">
            <b-dd-text v-bind:key="type" v-for="(sum,type) in prices.min">
              <div class="dropdown-checkbox"
                   :class="{active:searchParams.photographer_profession.indexOf(type) > -1 }"
                   v-on:click="setData('photographer_profession', type)">
                {{ $t('lists.photographer_profession.' + type) }}<br>
              </div>
            </b-dd-text>
          </b-dropdown>
          <!--                    <div-->
          <!--                            v-for="(sum,key) in prices.min"-->
          <!--                            class="dropdown-radio dropdown-radio-inline"-->
          <!--                            :class="{active:searchParams.photosession_type === key}"-->
          <!--                            v-on:click="setPhotoSessionType(key)"-->
          <!--                    >-->
          <!--                        {{ $t('lists.photosession_types.' + key) }} <br>-->
          <!--                        <span class="comment">{{ $t('search.filter.shoot_class_from') }} {{ sum }} {{ $t('search.filter.shoot_class_after') }}</span>-->
          <!--                    </div>-->
        </div>
        <hr>
      </div>
      <div class="form-group d-lg-none">
        <div class="custom-control custom-checkbox-block">
          <input type="checkbox" name="checkbox" id="checkbox-distance-modal" class="custom-control-input">
          <label for="checkbox-distance-modal" class="custom-control-label">{{ $t('search.filter.distance') }}</label>
        </div>
        <hr>
      </div>
      <div class="form-group">
        <button class="btn btn-secondary btn-block" v-on:click="reset()">Очистить фильтр</button>
      </div>
      <button class="btn btn-primary btn-block" @click="$bvModal.hide('modal-filter-params')">Показать</button>
    </b-modal>
    <div class="mobile-address d-md-none">
      <city-dropdown></city-dropdown>
    </div>
    <div v-if="loadingData || loadingFilters" class="search-loader">
      <div class="spinner-border text-primary"></div>
    </div>
    <div class="container container-wide" v-if="!with_map">
      <div class="inner-content-md">
        <p class="filter-result-count">{{ $t('search.found') }}
          {{ city === '' ? $place.getCity().ru : city[[$store.state.user.options.locale]] }}: {{ this.users.length }}</p>
        <div class="photographer-item-list">
          <div class="row">
            <div class="col-12 col-xl-4 col-xxl-3" v-for="user in users">
              <SearchPhotographer v-bind:key="'list_'+user.personal.id" :user="user"  :duration=getDurationText()></SearchPhotographer>
            </div>
          </div>
        </div>
      </div>
      <div class="show-map d-xl-none" v-if="isLiveSearch" v-on:click="with_map = true"></div>
    </div>
    <div class="filter-search-container" v-if="with_map">
      <div class="text-center mb-3 d-md-none">
        <button class="btn btn-white btn-sm" v-b-modal="'modal-filter-params'">Уточнить заказ</button>
      </div>
      <div class="filter-search-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col col-xl-width">
              <div class="inner-content-md">
                <p class="filter-result-count d-none d-xl-block">{{ $t('search.found') }}
                  {{ city === '' ? $place.getName() : city[$store.state.user.options.locale] }}: {{
                    this.users.length
                  }}</p>
                <div class="photographer-item-list">
                  <SearchPhotographer v-bind:key="'map_'+user.personal.id"
                                      :open-in-new-page="openInNewPage"
                                      :user="user"
                                      :duration=getDurationText()
                                      :is_map="true"
                                      v-for="user in users"></SearchPhotographer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-map" v-if="with_map">
      <div class="close" v-on:click="with_map = false"></div>
      <search-map :open-in-new-page="openInNewPage" :users="users" v-if="with_map"></search-map>
    </div>
    <short-order
        ref="shortOrder"
        v-if="orderDataReady()"
        :calendar="order_data.calendar"
        :user="order_data.user"
        :durations="order_data.durations"
        :order-data="order_data.data"
        :order="order_data.order"
    ></short-order>
  </div>
</template>

<script>
import SearchPhotographer from "./SearchPhotographer";
import SearchApi from "./services";
import ProfileApi from "../profile/service";
import SearchMap from "./SearchMap";
import datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import VueSlider from 'vue-slider-component';
import moment from 'moment';
import {BModal} from 'bootstrap-vue';
// import Autocomplete from "../widgets/Autocomplete";
import VueTimepicker from 'vue2-timepicker/src'

import 'vue-slider-component/theme/antd.css';
import ShortOrder from "../modals/ShortOrder";
import OrderInfoModal from "@/components/modals/OrderInfoModal";
import ChangePhone from "@/components/lk/components/widget/ChangePhone";
import PaymentForm from "@/components/payment/PaymentForm.vue";
import CityDropdown from "@/components/widgets/CityDropdown.vue";

let api, profileApi = undefined;
let defaultRage = [0, 1000000];

const startDurationIndex = 1;
const defaultPhotoSessionType = ['express', 'pro', 'ultra'];
const photographerProfession = ['photographer', 'video_creator', 'mobile_photographer'];

export default {
  name: "Search",
  components: {
    CityDropdown,
    PaymentForm,
    ChangePhone,
    OrderInfoModal,
    ShortOrder, SearchMap, SearchPhotographer, datepicker, VueSlider, BModal, VueTimepicker
  },
  data() {
    return {
      paymentDetails: {},
      openInNewPage: true,
      updateTimeout: undefined,
      city: '',
      startDate: moment().clone().toDate(),
      users: [],
      prices: {},
      isLiveSearch: false,
      timeChoose: [
        {text: '15m', value: 15, type: 'minutes'},
        {text: '30m', value: 30, type: 'minutes'},
        {text: '45m', value: 45, type: 'minutes'},
        {text: '1h', value: 60, type: 'minutes'}
      ],
      durationChoose: [
        {text: '15m', value: 15, type: 'minutes'},
        {text: '30m', value: 30, type: 'minutes'},
        {text: '1h', value: 60, type: 'minutes'},
        {text: '1,5h', value: 90, type: 'minutes'}
      ],
      loadingFilters: true,
      loadingData: true,
      fastChoose: undefined,
      disableDates: {
        to: moment().add(-1, 'day').endOf("day").toDate(),
      },
      photoSessionType: defaultPhotoSessionType,
      durationIndex: startDurationIndex,
      searchParams: {
        services: [],
        locations: [],
        equipment: [],
        start_date: moment().toDate(),
        duration: moment().startOf('day').add(this.getOrderDurations()[startDurationIndex].value, 'minutes'),
        // photosession_type: defaultPhotoSessionType,
        photographer_profession: photographerProfession,
        count_people: 'min',
        price: defaultRage
      },
      price: {
        ready: false,
        min: 0,
        max: 0,
        step: 100,
        range: [0, 0]
      },//TODO
      languages: lang,
      with_map: true,
      order_data: {
        ready: false,
        data: {
          hours: [],
          start_hours: {
            from: '',
            to: ''
          },
          equipment: [],
          additional_services: [],
          photosession_types: [],
        },
        order: {
          photographer: {
            id: 0
          },
          locations: [],
          city: {
            id: ''
          },
          start_date: '',
          is_full_day: false,
          duration: undefined,
          equipment: '',
          price: 0,
          services: [],
          photosession_type: '',
          count_people: 'min',

        },
        durations: [],
        user: undefined
      }
    }
  },
  mounted() {
    api = new SearchApi();
    api.lists();
    profileApi = new ProfileApi();
    if(this.$route.name === 'live_search'){
      this.with_map = true;
      this.isLiveSearch = true;
    }else{
      this.with_map = false;
      this.isLiveSearch = false;
    }
    this.$root.$on('update-place', () => {
      if (this.searchParams.city_id) {
        delete this.searchParams.city_id;
      }
      this.loadingData = true;
      this.users = [];

      this.getFilters(false);
    });

    if (this.$route.query.filters) {
      this.getParamsFromQuery();
    } else {
      let interval = setInterval(() => {
        if (
            this.$store.state.list.equipment.length > 0
            && this.$store.state.list.locations.length > 0
            && this.$store.state.list.additional_services.length > 0
        ) {
          if (this.$route.query.start_params) {
            let params = JSON.parse(this.$route.query.start_params);
            let date = moment(params.start_date);
            this.searchParams.start_date = date.toDate();
            let diff = date.diff(moment(), 'minutes');
            this.timeChoose.forEach((time, key) => {
              if (diff >= time.value - 2 && diff <= time.value) {
                this.setFast(this.timeChoose[key]);
              }
            })
            if (params.show_filters) {
              // this.$refs.timeSelector.show();
            }
          } else {
            this.setFast(this.timeChoose[0]);
          }

          this.searchParams.equipment = this.clone(this.$store.state.list.equipment);
          this.searchParams.locations = this.clone(this.$store.state.list.locations);
          this.searchParams.services = this.clone(this.$store.state.list.additional_services);


          clearInterval(interval);
        }
      }, 100)

    }

    this.getOrderDurations().forEach(duration => duration.tag !== 'all' ? this.order_data.durations.push(duration.tag) : '');

    this.$root.$on('create-order', (user) => {
      if (this.$metrika) {
        this.$metrika.reachGoal('Order');
      }
      this.$root.$emit('press_vk_button', 'click-button-find', 36514679);

      let interval;
      let loadCalendar = false;
      let loadData = false;
      this.order_data.user = user;
      profileApi
          .getCalendar(user.personal.id, this.city.id)
          .then((res) => {
            this.order_data.calendar = res.data.data;
            loadCalendar = true;
          });
      profileApi
          .getOrderData(user.personal.id)
          .then((res) => {
            this.order_data.data.equipment = res.data.data.equipment;
            this.order_data.data.photosession_types = res.data.data.photosession_types;
            this.order_data.data.additional_services = res.data.data.additional_services;
            this.order_data.data.locations = res.data.data.locations;
            this.order_data.order.photosession_type = res.data.data.photosession_types.general.value;
            this.clearData(this.searchParams.locations, 'locations', 'locations')
            this.clearData(this.searchParams.equipment, 'equipment', 'equipment')
            this.clearData(this.searchParams.services, 'services', 'additional_services')
            loadData = true;
          });

      this.order_data.order.duration = this.getOrderDurations()[this.durationIndex].tag;
      this.order_data.order.is_full_day = this.durationIndex === this.getOrderDurations().length - 1;
      this.order_data.order.photographer.id = user.personal.id;
      let date = this.searchParams.start_date;
      let minutes = moment(date).format('mm');
      if(minutes > 15 && minutes <= 45) {
        minutes = 30;
      }else{
        minutes = 0;
      }
      date.setMinutes(minutes);
      this.order_data.order.start_date = date;
      this.order_data.order.duration = this.searchParams.duration;

      this.loadingData = true;
      interval = setInterval(() => {
        if (loadCalendar && loadData) {
          this.order_data.ready = true;
          setTimeout(() => {
            this.loadingData = false;
            this.$bvModal.show('modal-reserve-photographer');
            this.$refs.shortOrder.getFullPrice();
            this.$refs.shortOrder.$on('book', this.booking);

          }, 100);
          clearInterval(interval);
        }
      }, 200);

    });
    this.$showJivo();
  },
  methods: {
    clearData(value, param, paramName) {
      this.order_data.order[param] = [];
      value.forEach((service) => {
        let index = this.order_data.data[paramName].indexOf(service);
        if (index !== -1) {
          this.order_data.order[param].push(service);
        }
      });
    },
    booking() {
      if (this.$metrika) {
        this.$metrika.reachGoal('Booking');
      }
      this.$root.$emit('press_vk_button', 'click-button-find', 36514681);

      if (this.$auth.check() !== true) {
        this.$bvModal.show('modal-login');
        return;
      } else if (!this.isPhoneVerified()) {
        this.$bvModal.show('modal-update-phone');
        return;
      } else {
        let order = JSON.parse(JSON.stringify(this.order_data.order));
        order.city.id = this.$place.getCity().id;
        order.promo_code = this.promo_code;
        order.start_date = moment(this.order_data.order.start_date).format('DD.MM.YYYY HH:mm');
        order.duration = moment(this.order_data.order.duration).diff(moment().startOf('day'), 'minutes');
        order.address = this.$place.getRawData().formatted_address;
        order.address_coordinates = encodeURIComponent(JSON.stringify(this.$place.getCoordinates() ? this.$place.getCoordinates() : {
          lat: 55.7558260,
          lng: 37.6172999
        }));//TODO
        // delete order.durationIndex; //TODO вобще снести нафиг
        this.$root.$emit('load-start');
        profileApi
            .createOrder(order)
            .then((res) => {
              this.paymentDetails = res.data.data.payment_info;
              if(this.paymentDetails.type === 'redirect') {
                this.$bvModal.show('modal-before-order-redirect');
                setTimeout(() => {
                  if (res.data.data.payment_info.payment_id) {
                    window.location.replace(res.data.data.payment_info.checkout_url);
                  } else {
                    this.$router.push({name: 'order.area'});
                  }
                }, 3000);
              }
            })
            .catch((error) => {
              this.$root.$emit('error', error.response.data.message);
            });
      }
    },
    orderDataReady() {
      return this.order_data.ready;
    },
    getDuration() {
      return moment(this.searchParams.duration).format('H:mm');
    },
    getDurationText() {
      let duration =  this.durationChoose.filter((el)=>{
        return el.value === this.searchParams.duration.diff(moment().startOf('day'), 'minutes');
      });
      if(duration.length > 0){
        return duration[0].text;
      }
      return '';
    },
    getDate() {
      return moment(this.searchParams.start_date).format('DD.MM.YYYY');
    },
    getTime() {
      return {
        HH: moment(this.searchParams.start_date).format('HH'),
        mm: moment(this.searchParams.start_date).format('mm')
      }
    },
    changeTime(time) {
      this.searchParams.start_date.setHours(time.data.HH);
      this.searchParams.start_date.setMinutes(time.data.mm);
    },
    changeDuration(index) {
      this.loadingFilters = true;
      this.changeDurationHelper(this.durationIndex, index, this.searchParams);
    },
    setFastDuration(time) {
      this.loadingFilters = true;
      let durations = this.getOrderDurations();
      durations.filter((a, b) => {
        if (a.value === time.value) {
          this.durationIndex = b;
        }
      });
      this.searchParams.duration = moment().startOf('day').add(durations[this.durationIndex].value, 'minutes');
    },
    isFastDurations(time) {
      return time.value === this.searchParams.duration.diff(moment().startOf('day'), 'minutes');
    },

    isFast(time) {
      let diff = moment(this.searchParams.start_date).diff(moment(), 'minutes');
      if (diff >= time.value - 2 && diff <= time.value) {
        return true;
      }
    },
    setFast(time) {
      this.fastChoose = time;
      this.searchParams.start_date = moment().add(time.value, time.type).toDate();
    },
    changePriceRange() {
      this.searchParams.price = this.price.range;
    },
    reset() {
      const defaultSearchParams = {
        duration: moment().startOf('day').add(this.getOrderDurations()[startDurationIndex].value, 'minutes'),
        // photosession_type: defaultPhotoSessionType,
        // profess: defaultPhotoSessionType,
        photographer_profession: photographerProfession,
        count_people: 'min',
        equipment: this.$store.state.list.equipment,
        locations: this.$store.state.list.locations,
        services: this.$store.state.list.additional_services,
        price: defaultRage
      };
      this.durationIndex = startDurationIndex;

      for (let [key, value] of Object.entries(defaultSearchParams)) {
        this.searchParams[key] = value;
      }
    },
    getParamsFromQuery() {
      for (let [key, value] of Object.entries(JSON.parse(this.$route.query.filters))) {
        if (value && value !== '') {
          if (key === 'duration') {
            let durations = this.getOrderDurations();
            durations.filter((a, b) => {
              if (a.value === value) {
                this.durationIndex = b;
              }
            });
            this.searchParams[key] = moment().startOf('day').add(value, 'minutes');
          } else if (key === 'start_date') {

            let date = moment(value).toDate();
            if (date instanceof Date && !isNaN(date)) {
              this.searchParams[key] = date;
            } else {
              this.searchParams[key] = moment().toDate();
            }


            setTimeout(() => {
              this.$forceUpdate();
            }, 1000);
          } else {
            this.searchParams[key] = value;
          }
        }
      }
    },
    getSearchParamsForQuery() {
      let search = {};
      if (this.$place.getCity()) {
        search.city_id = this.$place.getCity().id;
      } else {
        search.city_id = this.$store.state.user.options.city.id;
      }

      for (let [key, value] of Object.entries(this.searchParams)) {
        if (value && value !== '') {
          if (key === 'duration') {
            search[key] = value.diff(moment().startOf('day'), 'minutes');
          } else {
            search[key] = value;
          }
        }
      }
      return search;
    },
    getSearchParams() {
      let search = {};
      if (this.$place.getCity()) {
        search.city_id = this.$place.getCity().id;
      } else {
        search.city_id = this.$store.state.user.options.city.id;
      }
      // search.live =
      for (let [key, value] of Object.entries(this.searchParams)) {
        if (value && value !== '') {
          if (key === 'duration') {
            search[key] = value.diff(moment().startOf('day'), 'minutes');
          } else if (key === 'start_date') {
            search[key] = moment(value).format('DD.MM.YYYY HH:mm');
          } else {
            search[key] = value;
          }
        }
      }
      if(this.with_map){
        search['live'] = true;
      }
      return search;
    },
    getFilters(params) {
      params = this.getSearchParams();
      this.price.ready = false;
      delete params.price;
      this.users = [];
      api
          .getFilters(
              params
          )
          .then((res) => {
            this.prices = res.data.data.prices;
            this.price.min = res.data.data.range.min < 0 ? 0 : res.data.data.range.min;
            this.price.max = res.data.data.range.max;
            this.price.range = [this.price.min, res.data.data.range.max];
            this.price.ready = true;
            this.city = res.data.data.city;
            this.loadingFilters = false;
            this.loadingData = false;
            this.changePriceRange();
          })
    },
    search() {
      this.$root.$emit('press_vk_button', 'click-button-find', 36514605);
      this.loadingData = true;

      api
          .search(
              this.getSearchParams()
          )
          .then((res) => {
            this.users = [];
            let users = this.shuffle(res.data.data.users);

            for (let i = 0; i < users.length; i++) {
              setTimeout(() => {
                if (!users[i].profile.position.lat && !users[i].profile.position.lng) {
                  users[i].profile.position = {
                    lat: this.getClosestRandomLocation(this.getUserLocation().lat),
                    lng: this.getClosestRandomLocation(this.getUserLocation().lng)
                  }
                }
                this.users.push(users[i]);
              }, i * 10);
            }
            this.loadingData = false;
          })
    },
    getClosestRandomLocation(baseLoc) {
      return this.getRandomFloat(baseLoc - 0.05, baseLoc + 0.05)
    },
    getRandomFloat(min, max) {
      return Math.random() * (max - min) + min;
    },
    getUserLocation() {
      return this.$place.getCoordinates() ? this.$place.getLocations() : this.users.length > 0 ? this.users[0].profile.position : {
        lat: 55.7558260,
        lng: 37.6172999
      }
    },
    settingsByDevice() {
      let width = document.documentElement.clientWidth;
      if (width < 780) {
        this.openInNewPage = false;
        // this.with_map = false;
      } else if (width < 1200) {
        this.openInNewPage = false;
        //Планшет
      } else {
        //остальное
      }
    },
    setData(type, value) {
      this.loadingFilters = true;
      let index = this.searchParams[type].indexOf(value);
      if (index === -1) {
        this.searchParams[type].push(value)
      } else {
        this.searchParams[type].splice(index, 1);
      }
    },
  },
  watch: {
    startDate: {
      handler: function (newVal) {
        this.loadingFilters = true;
        this.searchParams.start_date = newVal;
      },
      deep: true
    },
    searchParams: {
      handler: function () {
        try {
          if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
          }
          this.updateTimeout = setTimeout(() => {
            if (this.$route.query.city !== this.$place.getRawData().formatted_address || JSON.stringify(this.getSearchParamsForQuery()) !== this.$route.query.filters) {
              this.$router.push({
                query: {
                  city: this.$place.getRawData().formatted_address,
                  filters: JSON.stringify(this.getSearchParamsForQuery())
                }
              });
            }
            if (this.loadingFilters) {
              setTimeout(() => {
                this.getFilters(false)
              }, 100);
            } else {
              this.search();
            }
          }, 1500);
        } catch (e) {
          //skip
        }

      },
      deep: true
    },


  }
}
</script>

<style scoped>

</style>